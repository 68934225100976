import React, { useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import Logo from "../../../Image/Logo.png";
import LogoSub from "../../../Image/LogoSub.png";
import { BiLogOut, BiUserCircle } from "react-icons/bi";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import "./Topview.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Profile from "./Profile/Profile";
import Ledger from "./Ledger/Ledger";
import Rules from "./Rules/Rules";
import News from "./News/News";

export default function TopView() {
  const path = window.location.pathname;
  const navigation = useNavigate();
  const theme = useSelector((state) => state.theme);

  const [select, setSelect] = useState(false);
  const [profile, setProfile] = useState(false);
  const [ledger, setLedger] = useState(false);

  const [checked, setChecked] = useState(1);

  const [rules, setRules] = useState(false);
  const [news, setNews] = useState(false);

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("server");
    localStorage.removeItem("AuthState");
    window.location = "/";
  };

  // useKeypress(["H", "W", "C", "S", "T", "P", "H", "W", "B"], (event) => {
  //   if (event.shiftKey == true) {
  //     if (event.key === "D") {
  //       navigation("/");
  //     }
  //     if (event.key === "W") {
  //       navigation("/Watchlist");
  //     }
  //     if (event.key === "C") {
  //       navigation("/Master");
  //     }
  //     if (event.key === "S") {
  //       navigation("/Position");
  //     }
  //     if (event.key === "T") {
  //       navigation("/Trade");
  //     }
  //     if (event.key === "P") {
  //       navigation("/Pending");
  //     }
  //     if (event.key === "H") {
  //       navigation("/Holding");
  //     }
  //     if (event.key === "B") {
  //       navigation("/Account");
  //     }
  //     if (event.key === "R") {
  //       navigation("/Report");
  //     }
  //   }
  // });

  return (
    <div
      className="topview"
      style={{ height: 100, backgroundColor: Dark.background, display: "flex" }}
    >
      <Link
        to={"/"}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={Logo} style={{ height: "70px" }} />
        <img src={LogoSub} style={{ height: "30px" }} />
      </Link>
      <div
        style={{
          flex: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          className="link"
          to={"Home"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/" || path == "/Home" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          HOME
        </Link>

        <Link
          className="link"
          to={"Watchlist"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/Watchlist" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          WATCHLIST
        </Link>

        <Link
          className="link"
          to={"Super"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Super" ? Dark.primary : Dark.background,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 7,
          }}
        >
          SUPER
        </Link>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/ParkingPosition" ||
                path == "/PositionTrade" ||
                path == "/Position"
                  ? Dark.primary
                  : Dark.background,
            }}
          >
            POSITION
          </button>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"PositionTrade"}
            >
              TRADE WISE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"Position"}
            >
              POSITION WISE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"PositionSuper"}
            >
              SUPER WISE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"PositionMaster"}
            >
              MASTER WISE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"PositionClient"}
            >
              CLIENT WISE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"ParkingPosition"}
            >
              PARKING POSITION
            </Link>
          </div>
        </div>
        <Link
          className="link"
          to={"Trade"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: path == "/Trade" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          TRADE
        </Link>
        <Link
          className="link"
          to={"Pending"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            backgroundColor:
              path == "/Pending" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          PENDING
        </Link>
        <Link
          className="link"
          to={"Holding"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor:
              path == "/Holding" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          HOLDING
        </Link>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
              path == "/SuperReport" || path == "/MasterReport" || path == "/ClientReport"
                  ? Dark.primary
                  : Dark.background,
            }}
          >
            REPORT
          </button>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"SuperReport"}
            >
              SUPER
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"MasterReport"}
            >
              MASTER
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"ClientReport"}
            >
              CLIENT
            </Link>
          </div>
        </div>

        <Link
          className="link"
          to={"Brokerage"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "110px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            padding: 10,
            backgroundColor:
              path == "/Brokerage" ? Dark.primary : Dark.background,
            borderRadius: 7,
          }}
        >
          BROKERAGE
        </Link>
      </div>

      <div
        style={{
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="dropdownprofile">
          <button class="dropbtnprofile">
            {" "}
            <BiUserCircle
              color={theme == 0 ? Dark.text : Light.text}
              fontSize={30}
            />{" "}
          </button>
          <div class="dropdown-contentprofile">
            <Link
              onClick={() => {
                setSelect(!select);
                setProfile(true);
              }}
              className="link"
            >
              PROFILE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
                setLedger(true);
              }}
              className="link"
            >
              LEDGER
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
                setRules(true);
              }}
              className="link"
            >
              RULES
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
                setNews(true);
              }}
              className="link"
            >
              NEWS
            </Link>
          </div>
        </div>

        <div onClick={Logout} style={{ marginLeft: 20 }}>
          <BiLogOut color={Dark.text} fontSize={30} />
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={profile}
        style={{
          width: "40vw",
          height: "57vh",
        }}
        onHide={() => setProfile(false)}
      >
        <Profile />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={ledger}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setLedger(false)}
      >
        <Ledger />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={rules}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setRules(false)}
      >
        <Rules />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={news}
        style={{ width: "60vw", height: "65vh" }}
        onHide={() => setNews(false)}
      >
        <News />
      </Dialog>
    </div>
  );
}
