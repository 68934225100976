import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },

  container: {
    width: "100%",
    marginTop: "15px",
  },
  Boxwrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 15px 0px 15px",
    borderBottom: "2px",
    borderColor: "black",
  },
  Box: {
    width: "31%",
    flexDirection: "column",
    marginBottom: "5px",
  },
  resultContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
  },

  LastSectionContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },

  LastSection: {
    width: "98%",
    marginTop: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  RightLastSection: {
    width: "40%",
    flexDirection: "column",
    gap: "9px",
  },
  rightContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "9px",
  },
});

// ------ JSX SECTION ---------
const Header = (props) => {
  let pl = parseFloat(props.pl == null ? 0 : props.pl).toFixed(0);
  let brokerage = parseFloat(
    props.brokerage == null ? 0 : props.brokerage
  ).toFixed(0);
  let brokerage_broker = parseFloat(
    props.brokerage_broker == null ? 0 : props.brokerage_broker
  ).toFixed(0);

  let future = parseFloat(props.future == null ? 0 : props.future).toFixed(0);
  let futurebroker = parseFloat(
    props.futurebroker == null ? 0 : props.futurebroker
  ).toFixed(0);

  let mcx = parseFloat(props.mcx == null ? 0 : props.mcx).toFixed(0);
  let mcxbroker = parseFloat(
    props.mcxbroker == null ? 0 : props.mcxbroker
  ).toFixed(0);

  let options = parseFloat(props.options == null ? 0 : props.options).toFixed(
    0
  );
  let optionebroker = parseFloat(
    props.optionebroker == null ? 0 : props.optionebroker
  ).toFixed(0);

  let sharing = parseFloat(props.sharing).toFixed(0);

  let sharingbrokrage = parseFloat(props.sharingbrokrage).toFixed(0);

  let netamount = parseFloat(
    parseFloat(pl) - parseFloat(sharing) + parseFloat(sharingbrokrage)
  ).toFixed(0);

  return (
    <View style={styles.container}>
      {/* box wrapper */}
      <View style={styles.Boxwrapper}>
        {/* MCX SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            MCX
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Brokerage</Text>
              <Text>
                {mcx}
                {"Dr"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Return</Text>
              <Text>
                {mcxbroker} {"Cr"}
              </Text>
            </View>
            {/* <View style={styles.content}>
              <Text>Net Amt</Text>
              <Text>
                {paxpl} {mcxpl > 0 ? "Cr" : "Dr"}
              </Text>
            </View> */}
          </View>
        </View>

        {/* NSE SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            NSE
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Brokerage</Text>
              <Text>
                {future}
                Dr
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Return</Text>
              <Text>{futurebroker} Cr</Text>
            </View>
          </View>
        </View>

        {/* NCDEX SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            OPTIONS
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Brokerage</Text>
              <Text>
                {options}
                Dr
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Return</Text>
              <Text>{optionebroker} Cr</Text>
            </View>
          </View>
        </View>
      </View>

      {/* LAST SECTION */}
      <View style={styles.LastSectionContainer}>
        <View style={styles.LastSection}>
          {/* left side */}
          <Text style={{ width: "10%", fontSize: "8px", marginTop: "40px" }}>
            E.&.O.E
          </Text>

          <View style={styles.RightLastSection}>
            <View style={styles.rightContent}>
              <Text>Gross Amount</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{pl > 0 ? pl : pl.replace("-", "")}</Text>
                <Text>{parseFloat(pl) > 0 ? " Cr" : " Dr"}</Text>
              </View>
            </View>

            {/* <View style={styles.rightContent}>
              <Text>Brokerage</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{brokerage}</Text>
                <Text style={{ color: "black" }}>{"Dr"}</Text>
              </View>
            </View> */}

            <View style={styles.rightContent}>
              <Text> Broker Sharing Amt</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{sharing > 0 ? sharing : sharing.replace("-", "")}</Text>
                <Text>{parseFloat(sharing) > 0 ? " Dr" : " Cr"}</Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>Broker Brok Amt </Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>
                  {sharingbrokrage > 0
                    ? sharingbrokrage
                    : sharingbrokrage.replace("-", "")}
                </Text>
                <Text style={{ fontFamily: "Times-Bold" }}>Cr</Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>Party Net Amt</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>
                  {netamount > 0 ? netamount : netamount.replace("-", "")}
                </Text>
                <Text style={{ fontFamily: "Times-Bold" }}>
                  {parseFloat(netamount) > 0 ? " Cr" : " Dr"}
                </Text>
              </View>
            </View>

            <View
              style={{ flexDirection: "row", gap: "17px", marginTop: "5px" }}
            >
              <Text style={{ fontSize: "7px" }}>For</Text>
              <Text style={{ fontSize: "12", fontFamily: "Times-Bold" }}>
                SOFT
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* container ending tag */}
    </View>
  );
};

export default Header;
