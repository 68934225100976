// components/PdfViewer.js
// eslint-disable-next-line no-unused-vars
import React from "react";
import PdfDocument from "./PDFGenerator";

const PdfViewer = (props) => {


  return (
    <div className="flex items-center justify-center h-screen">
      <PdfDocument
        sharingp={props.sharingp}
        netamount={props.netamount}
        breture={props.breture}
        sharing={props.sharing}
        m2m={props.m2m}
        brokerage={props.brokerage}
        pl={props.pl}
        futurepl={props.futurepl}
        futurebrok={props.futurebrok}
        futurevol={props.futurevol}
        mcxpl={props.mcxpl}
        mcxbrok={props.mcxbrok}
        mcxvol={props.mcxvol}
        optionspl={props.optionspl}
        optionsbrok={props.optionsbrok}
        optionsvol={props.optionsvol}
        username={props.username}
        name={props.name}
        start={props.start}
        end={props.end}
        
      />
    </div>
  );
};

export default PdfViewer;
