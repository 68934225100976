import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";

export default function Trade(props) {
  return (
    <div>
      <div
        style={{
          height: 30,
          display: "flex",
          backgroundColor: Dark.background,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          borderRadius: 10,
          paddingLeft:10,
          textTransform:"uppercase"
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          SYMBOL
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          B/S
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          QTY
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          RATE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          TYPE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          DEPOSIT
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          BROKERAGE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          P&L
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          DATE
        </div>
      </div>
      {props.trade.length > 0
        ? props.trade.map((i) => {
            return (
              <div
                style={{
                  height: 50,
                  display: "flex",
                  backgroundColor: Dark.background,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  borderRadius: 10,
                  paddingLeft:10,
                  textTransform:"uppercase"
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.symbol}
                </div>
                <div
                  style={{
                    color: i.buy_sell == 0 ? Dark.buy : Dark.sell,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.buy_sell == 0 ? "BUY" : "SELL"}
                </div>
                <div
                  style={{
                    color: "#fff",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.qty}
                </div>
                <div
                  style={{
                    color: "#fff",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.rate}
                </div>
                <div
                  style={{
                    color:
                      i.type == "NEW SAUDA"
                        ? Dark.primary
                        : i.type == "SAUDA ADDED"
                        ? Dark.buy
                        : i.type == "SAUDA REMOVE"
                        ? Dark.sell
                        : i.type == "SAUDA SQ.Off"
                        ? Dark.sell
                        : Dark.text,
                    flex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  {i.type}
                </div>
                <div
                  style={{
                    color: "#fff",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  {i.new_deposit}
                </div>
                <div
                  style={{
                    color: Dark.sell,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.brokerage_client_amt}
                </div>
                <div
                  style={{
                    color: i.profit_loss > 0 ? Dark.buy : Dark.sell,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  {i.profit_loss}
                </div>
                <div
                  style={{
                    color: Dark.text,
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 9,
                    textAlign:"center"
                  }}
                >
                  {moment(i.date_created).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
