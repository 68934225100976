import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function BrokerCard(props) {
  const i = props.item;
  return i.broker == 0 ? null : (
    <div
      onClick={() => props.updateBroker(i)}
      style={{
        margin: 10,
        padding: 10,
        backgroundColor: props.id == i.broker ? Dark.primary : Dark.secondary,
        width: "95%",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        marginBottom: 10,
      }}
    >
      {i.broker_name}
    </div>
  );
}
