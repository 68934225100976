import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function PdfCard(props) {
  const item = props.item;

  let profit_loss_day = parseFloat(item.profit_loss_day).toFixed(0);
  let profit_loss_week = parseFloat(item.profit_loss_week).toFixed(0);

  let sharing_day = parseFloat(item.sharing_day).toFixed(0);
  let sharing_week = parseFloat(item.sharing_week).toFixed(0);

  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.text }}>
          {item.username}({item.name})
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: profit_loss_day > 0 ? Dark.buy : Dark.sell,
          }}
        >
          {profit_loss_day}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: profit_loss_week > 0 ? Dark.buy : Dark.sell,
          }}
        >
          {profit_loss_week}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: Dark.text,
          }}
        >
          {item.sharing}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: sharing_day > 0 ? Dark.buy : Dark.sell,
          }}
        >
          {item.sharing_day}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: sharing_week > 0 ? Dark.buy : Dark.sell,
          }}
        >
          {item.sharing_week}
        </Text>
      </View>
    </View>
  );
}
