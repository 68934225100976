import React, { useContext, useEffect } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Menu from "./Menu";
import { Dark } from "../../../../../Theme/Theme";
import PositionCard from "./PositionCard";
import "./Pdf.css";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#000",
    display: "flex",
  },
  sectionheader: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
  },
  sectionfooter: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  section1: {
    margin: 10,
    padding: 10,
    backgroundColor: Dark.secondary,
    borderRadius: 10,
  },
});

// Create Document Component
const Pdf = (props) => {

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View fixed style={styles.sectionheader}>
          <View style={{ flex: 1, paddingLeft: 20 }}>
            <Text style={{ fontSize: 16, color: Dark.text }}>
              {props.username}({props.name}) - STANDING{" "}
            </Text>
            <Text style={{ marginTop: 5, fontSize: 12, color: Dark.text }}>
              {props.start} - {props.end}
            </Text>
          </View>
          <View
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: Dark.background,
              borderRadius: 10,
              marginRight: 10,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: props.profit_loss_week > 0 ? Dark.buy : Dark.sell,
              }}
            >
              Booked : {parseFloat(props.profit_loss_week).toFixed(2)}
            </Text>
          </View>
        </View>

        <Menu />
        {props.standing.length > 0
          ? props.standing.map((i) => {
              return (
                <PositionCard
                  item={i}
                  socket={props.socket}
                />
              );
            })
          : null}

        <View
          style={{
            height: 100,
            backgroundColor: Dark.primary,
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                height: 80,
                width: 200,
                backgroundColor: Dark.secondary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  marginBottom: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                    flex: 1,
                    paddingLeft: 10,
                  }}
                >
                  M2M
                </Text>
                <Text
                  style={{
                    color: props.m2m > 0 ? Dark.buy : Dark.sell,
                    fontSize: 12,
                    paddingRight: 10,
                  }}
                >
                  {parseFloat(props.m2m).toFixed(0)}
                </Text>
              </View>
              <View
                style={{
                  marginBottom: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                    flex: 1,
                    paddingLeft: 10,
                  }}
                >
                  BROKERAGE
                </Text>
                <Text
                  style={{
                    color: Dark.sell,
                    fontSize: 12,
                    paddingRight: 10,
                  }}
                >
                  {parseFloat(props.btotal).toFixed(0)}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    color: Dark.text,
                    fontSize: 12,
                    flex: 1,
                    paddingLeft: 10,
                  }}
                >
                  NET AMOUNT
                </Text>
                <Text
                  style={{
                    color: props.profit_loss > 0 ? Dark.buy : Dark.sell,
                    fontSize: 12,
                    paddingRight: 10,
                  }}
                >
                  {parseFloat(props.profit_loss).toFixed(0)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
