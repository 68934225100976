import React, { useContext } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Menu from "./Menu";
import TotalCard from "./TotalCard";
import { Dark } from "../../../../../Theme/Theme";
import TradeCard from "./TradeCard";
import "./Trade.css";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#000",
    display: "flex",
  },
  sectionheader: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
  },
  sectionfooter: {
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Dark.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  section1: {
    margin: 10,
    padding: 10,
    backgroundColor: Dark.secondary,
    borderRadius: 10,
  },
});

// Create Document Component
const TradePdf = (props) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Menu fixed />
        {props.trade.length > 0
          ? props.trade.map((i) => {
              return <TradeCard item={i} />;
            })
          : null}
        <TotalCard
          buy_volume={props.buy_volume}
          sell_volume={props.sell_volume}
          trade={props.trade}
          profit_loss={props.profit_loss}
          brokerage={props.brokerage}
          m2m={props.m2m}
        />
      </Page>
    </Document>
  );
};

export default TradePdf;
