import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
import { View, Text } from "@react-pdf/renderer";

export default function ClientCard(props) {
  const item = props.item;
  let m2m = item.profit_loss + item.brokerage_client_amt;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 9, color: Dark.text }}>
          {item.master_username}{" "}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 9, color: Dark.text }}>
          {item.client_username}{" "}
        </Text>
      </View>
      <View
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: Dark.text,
          }}
        >
          {item.symbol}
        </Text>
        <Text
          style={{
            fontSize: 8,
            color: Dark.text,
            marginTop: 3,
          }}
        >
          {moment(item.expiry_date).format("DD-MMM")}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          }}
        >
          {item.buy_sell == 0 ? "BUY" : "SELL"}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 12, color: Dark.text }}>{item.qty}</Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 8, color: Dark.text }}>{item.rate}</Text>
      </View>
      <View
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color:
              item.type == "NEW SAUDA"
                ? Dark.primary
                : item.type == "SAUDA ADDED"
                ? Dark.buy
                : item.type == "SAUDA REMOVE"
                ? Dark.sell
                : item.type == "SAUDA SQ.Off"
                ? Dark.sell
                : Dark.sell,
            textAlign: "center",
          }}
        >
          {item.type}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {item.new_deposit}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 8,
            color: item.options == 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.type == "NEW SAUDA" || item.type == "SAUDA ADDED"
            ? "0"
            : parseFloat(m2m).toFixed(2)}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.sell,
            textAlign: "center",
          }}
        >
          {item.brokerage_client_amt}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: item.profit_loss < 0 ? Dark.sell : Dark.buy,
            textAlign: "center",
          }}
        >
          {item.profit_loss}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {item.other == null ? "-" : item.other}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {moment(item.date_created).format("DD-MM-YYYY")}
        </Text>
      </View>
    </View>
  );
}
