import React from "react";
import Menu from "./Menu";
import Total from "./Total";
import TradeCard from "./TradeCard";
import { Dark } from "../../../../Theme/Theme";
import "../Home.css";
import Loading from "react-loading";
export default function LiveTrade(props) {
  return (
    <div>
      <Total trade={props.trade} />
      <Menu sort={props.sort} />

      <div
        className="scroll"
        style={{
          display: "flex",
          height: "50vh",
          overflowY: "scroll",
          flexDirection: "column",
        }}
      >
        {props.loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Loading type="spin" height={40} width={40} color={Dark.text} />
          </div>
        ) : props.trade.length > 0 ? (
          props.trade.map((i) => {
            return <TradeCard key={i.id} item={i} />;
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Trade Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
