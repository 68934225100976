import React from "react";
import { Dark } from "../../../../Theme/Theme";
import Status from "./Status";
import { toast } from "react-toast";
export default function SuperCard(props) {
  const i = props.item;

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(`
      🙏 WELCOME TO 🙏 
      SAUDA
  😎 SUPER 》》
        
  🌐 SERVER 》》 ${i.server}
  🚹 USER NAME 》》 ${i.username}
  🚹 NAME 》》 ${i.name}
  🔐 PASSWORD 》》 ${"RAM007"}
  🔐 TPASSWORD 》》 ${i.tpassword}
  🔐 MARGIN 》》 ${i.sharing}
  
  
  BROKRAGE MIN 💰
  F & O MIN : ${i.future_min_volume}
  MCX LOT : ${i.mcx_min_lot}
  MCX VOLUME : ${i.mcx_min_volume}
  OPTIONS : ${i.options_min_lot}
  MARGIN : F : ${i.multiplier_future}, M :${i.multiplier_mcx}, O :${
        i.multiplier_options
      }
  Auto Square Off : ${i.auto_sq == 1 ? "On" : "Off"}
  🖥 FOR PC / IOS  : https://super.sauda.io;

  Web/Desktop : https://super.sauda.io;
  
  CONTACT YOUR UPLINE
 IF YOU FACE ANY PROBLEM IN TRADING
      `);

      toast.success("Copied", {
        backgroundColor: Dark.buy,
        color: Dark.text,
      });
    } catch (err) {
      toast.error("Failed to copy!", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    }
  };
  return (
    <div
      onDoubleClick={copyToClipBoard}
      onClick={() => props.setSuper(i)}
      style={{
        backgroundColor: Dark.secondary,
        color: Dark.text,
        padding: 5,
        height: 120,
        marginBottom: 15,
        borderRadius: 10,
        display: "flex",
        borderStyle: "solid",
        borderColor: props.super.id == i.id ? Dark.primary : Dark.secondary,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Server - {i.server}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Username - {i.username}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>Name - {i.name}</div>
        <div style={{ paddingBottom: 5, fontSize: 12 }}>
          Sharing : {i.sharing}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 12 }}>
          Margin L : F : {i.multiplier_future} M : {i.multiplier_mcx} O :
          {i.multiplier_options}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Future :{" "}
          <div style={{ color: i.future == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.future == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Mcx :{" "}
          <div style={{ color: i.mcx == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.mcx == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Options :{" "}
          <div style={{ color: i.options == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.options == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Only Sq :{" "}
          <div style={{ color: i.only_sq == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.only_sq == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>
        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          TPassword : <div style={{ color: Dark.text }}> {i.tpassword}</div>
        </div>

        <div
          style={{
            paddingBottom: 5,
            fontSize: 12,
            color: Dark.primary,
          }}
        >
          <Status id={i.id} />
        </div>
      </div>
    </div>
  );
}
