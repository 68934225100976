// const api_link = "http://localhost:50018";
// const api_mtrade = "http://localhost:50005";
const api_link = "https://sauda.io:50018";
const api_mtrade = "https://sauda.io:50005";
const EntryKeyAdmin = "219336af40024257897399c8d5401a6b";
const EntryKeyMtrade = "f04ef7a49d4cbb4809b19fe77d37fdfc";

export default class Backend {
  login(data) {
    return fetch(api_link + "/login", {
      method: "POST",
      headers: {
        EntryKeyAdmin: EntryKeyAdmin,
        "Content-Type": "application/json",
        accept: "application/json",
        id: data.id,
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_marquee(data) {
    return fetch(api_link + "/load_marquee", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        server: data.server,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  other(data) {
    return fetch(api_link + "/other", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        server: data.server,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_all_data(data) {
    return fetch(api_link + "/load_all_data", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  live_trade(data) {
    return fetch(api_link + "/live_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol(data) {
    return fetch(api_link + "/load_symbol", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary(data) {
    return fetch(api_link + "/load_summary", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status(data) {
    return fetch(api_link + "/check_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_master(data) {
    return fetch(api_link + "/add_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status_client(data) {
    return fetch(api_link + "/check_status_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client(data) {
    return fetch(api_link + "/load_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_master(data) {
    return fetch(api_link + "/load_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  single_super(data) {
    return fetch(api_link + "/single_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_admin(data) {
    return fetch(api_link + "/load_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_super_details(data) {
    return fetch(api_link + "/edit_super_details", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_super_brokerage(data) {
    return fetch(api_link + "/edit_super_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_super_margin(data) {
    return fetch(api_link + "/edit_super_margin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_ledger(data) {
    return fetch(api_link + "/super_ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_login_log(data) {
    return fetch(api_link + "/super_login_log", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
 super_future(data) {
    return fetch(api_link + "/super_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
 super_mcx(data) {
    return fetch(api_link + "/super_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_options(data) {
    return fetch(api_link + "/super_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_edit_qty(data) {
    return fetch(api_link + "/super_edit_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_reset_qty(data) {
    return fetch(api_link + "/super_reset_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_status(data) {
    return fetch(api_link + "/super_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_future_status(data) {
    return fetch(api_link + "/super_future_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_mcx_status(data) {
    return fetch(api_link + "/super_mcx_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_options_status(data) {
    return fetch(api_link + "/super_options_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_onlysq_status(data) {
    return fetch(api_link + "/super_onlysq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_block(data) {
    return fetch(api_link + "/load_block", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_block_add(data) {
    return fetch(api_link + "/super_block_add", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_block_remove(data) {
    return fetch(api_link + "/super_block_remove", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_index(data) {
    return fetch(api_link + "/load_index", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_client(data) {
    return fetch(api_link + "/load_standing_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_master(data) {
    return fetch(api_link + "/load_standing_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_super(data) {
    return fetch(api_link + "/load_standing_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_standing(data) {
    return fetch(api_link + "/load_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_standing(data) {
    return fetch(api_link + "/super_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  master_standing(data) {
    return fetch(api_link + "/master_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_standing(data) {
    return fetch(api_link + "/client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_standing(data) {
    return fetch(api_link + "/symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_super_standing(data) {
    return fetch(api_link + "/load_super_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_master_standing(data) {
    return fetch(api_link + "/load_master_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_standing(data) {
    return fetch(api_link + "/load_client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing(data) {
    return fetch(api_link + "/load_symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_parking(data) {
    return fetch(api_link + "/load_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_standing_parking(data) {
    return fetch(api_link + "/super_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_super_standing_parking(data) {
    return fetch(api_link + "/load_super_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  master_standing_parking(data) {
    return fetch(api_link + "/master_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_master_standing_parking(data) {
    return fetch(api_link + "/load_master_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_standing_parking(data) {
    return fetch(api_link + "/client_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_standing_parking(data) {
    return fetch(api_link + "/load_client_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_standing_parking(data) {
    return fetch(api_link + "/symbol_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing_parking(data) {
    return fetch(api_link + "/load_symbol_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_trade(data) {
    return fetch(api_link + "/load_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing_trade(data) {
    return fetch(api_link + "/load_symbol_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_standing_trade(data) {
    return fetch(api_link + "/load_client_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_master_standing_trade(data) {
    return fetch(api_link + "/load_master_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_super_standing_trade(data) {
    return fetch(api_link + "/load_super_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_trade(data) {
    return fetch(api_link + "/load_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  super_trade(data) {
    return fetch(api_link + "/super_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  master_trade(data) {
    return fetch(api_link + "/master_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_trade(data) {
    return fetch(api_link + "/client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_trade(data) {
    return fetch(api_link + "/symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_super_trade(data) {
    return fetch(api_link + "/load_super_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_master_trade(data) {
    return fetch(api_link + "/load_master_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_trade(data) {
    return fetch(api_link + "/load_client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_trade(data) {
    return fetch(api_link + "/load_symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_pending(data) {
    return fetch(api_link + "/pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "applicaload_master_pendingtion/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_master_pending(data) {
    return fetch(api_link + "/load_master_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_pending(data) {
    return fetch(api_link + "/load_client_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_pending(data) {
    return fetch(api_link + "/load_symbol_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  master_pending(data) {
    return fetch(api_link + "/master_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_pending(data) {
    return fetch(api_link + "/client_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_pending(data) {
    return fetch(api_link + "/symbol_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_holding(data) {
    return fetch(api_link + "/load_holding", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_brokerage(data) {
    return fetch(api_link + "/symbol_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_brokerage_update(data) {
    return fetch(api_link + "/symbol_brokerage_update", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_report(data) {
    return fetch(api_link + "/client_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  master_report(data) {
    return fetch(api_link + "/master_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_report(data) {
    return fetch(api_link + "/super_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  brokerage_report(data) {
    return fetch(api_link + "/brokerage_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  broker_list(data) {
    return fetch(api_link + "/broker_list", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  master_list(data) {
    return fetch(api_link + "/master_list", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_payin(data) {
    return fetch(api_link + "/load_payin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cancel_payin(data) {
    return fetch(api_link + "/cancel_payin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  pass_payin(data) {
    return fetch(api_link + "/pass_payin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_payout(data) {
    return fetch(api_link + "/load_payout", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cancel_payout(data) {
    return fetch(api_link + "/cancel_payout", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  pass_payout(data) {
    return fetch(api_link + "/pass_payout", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_account(data) {
    return fetch(api_link + "/load_account", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_broker(data) {
    return fetch(api_link + "/client_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_broker(data) {
    return fetch(api_link + "/add_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_update(data) {
    return fetch(api_link + "/broker_update", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_remove(data) {
    return fetch(api_link + "/broker_remove", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_brokerage(data) {
    return fetch(api_link + "/broker_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  carry_standing(data) {
    return fetch(api_link + "/carry_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  buy_sell(data) {
    return fetch(api_link + "/buy_sell", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_sq(data) {
    return fetch(api_mtrade + "/super_sq", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyMtrade: EntryKeyMtrade,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  permission(data) {
    return fetch(api_link + "/permission", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_report(data) {
    return fetch(api_link + "/load_client_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  profile(data) {
    return fetch(api_link + "/profile", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  ledger(data) {
    return fetch(api_link + "/ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_super(data) {
    return fetch(api_link + "/add_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_super(data) {
    return fetch(api_link + "/load_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  rules(data) {
    return fetch(api_link + "/rules", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_rules(data) {
    return fetch(api_link + "/add_rules", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_rules(data) {
    return fetch(api_link + "/delete_rules", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  news(data) {
    return fetch(api_link + "/news", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_news(data) {
    return fetch(api_link + "/add_news", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_news(data) {
    return fetch(api_link + "/delete_news", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyAdmin: EntryKeyAdmin,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
}
