import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },

  header: {
    textAlign: "center",
    marginBottom: 5,
    // backgroundColor: 'yellow', // Change header background color
    flexDirection: "column", // Arrange content horizontally
  },
  subHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    marginBottom: "3px",
  },
  subHeading2: {
    marginTop: "3px",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  TagContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "10px",
    width: "100%",
    borderBottom: "1px",
    borderBottomColor: "rgba(0,0,0,0.1)",
  },
  HeaderTagLeft: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    width: "90%",
    paddingTop: 5,
    paddingBottom: 5,
  },
});

// ------ JSX SECTION ---------
const TestingPage = (props) => {
  let data = props.data;

  let brokerage_broker = parseFloat(
    data.brokerage_broker == null ? 0 : data.brokerage_broker
  ).toFixed(0);

  let brokerage = parseFloat(
    data.brokerage == null ? 0 : data.brokerage
  ).toFixed(0);
  let profit_loss = parseFloat(
    data.profit_loss == null ? 0 : data.profit_loss
  ).toFixed(0);

  let sharing = parseFloat(data.sharing).toFixed(0);

  let sharing_brokrage = parseFloat(data.sharing_brokrage).toFixed(0);

  return (
    <View style={styles.header}>
      <View style={styles.TagContainer}>
        {/* ---- left side ------ */}
        <View style={[styles.HeaderTagLeft, styles.Family]}>
          <Text style={{ fontSize: 10, width: "16.6%" }}>
            {data.client_username}
          </Text>
          <Text style={{ fontSize: 10, width: "16.6%" }}>
            {profit_loss > 0 ? profit_loss : profit_loss.replace("-", "")}{" "}
            {profit_loss > 0 ? " Cr" : " Dr"}
          </Text>
          <Text style={{ fontSize: 10, width: "16.6%" }}>{brokerage} Dr</Text>
          <Text style={{ fontSize: 10, width: "16.6%" }}>
            {brokerage_broker} Cr
          </Text>
          <Text style={{ fontSize: 10, width: "16.6%" }}>
            ({100 - data.broker_sharing}%) {sharing_brokrage} Cr
          </Text>
          <Text style={{ fontSize: 10, width: "16.6%"  }}>
            {" "}
            {sharing > 0 ? sharing : sharing.replace("-", "")}(
            {data.broker_sharing}%) {sharing > 0 ? " Dr" : " Cr"}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TestingPage;
