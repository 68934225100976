import React from "react";
import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import LastFooterSection from "./LastFooterSection";
import { Dark } from "../../Theme/Theme";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },
  headingText1: {
    borderBottom: "2px",
    borderBottomColor: "black",
    textAlign: "center",
  },
  subHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    marginBottom: "3px",
  },
  boldText: {
    fontWeight: "bold",
  },
  page: {
    fontFamily: "Helvetica",
    paddingTop: 20,
    paddingBottom: 50,
    paddingHorizontal: 30,
  },

  footer: {
    position: "absolute",
    bottom: 0,
    right: 10,
    textAlign: "center",
    marginTop: 10,
    fontSize: 10,
    color: "blue",
  },
  content: {
    marginBottom: 8,
  },
  headingText2: {
    borderBottom: "2px",
    borderBottomColor: "black",
  },
});

// JS + react-pdf/render section
const PdfDocument = (props) => {
  let username = props.username;
  let name = props.name;

  let sharingp = props.sharingp;
  let netamount = props.netamount;
  let breture = props.breture;
  let sharing = props.sharing;
  let m2m = props.m2m;
  let brokerage = props.brokerage;
  let pl = props.pl;
  let futurepl = props.futurepl;
  let futurebrok = props.futurebrok;
  let futurevol = props.futurevol;
  let mcxpl = props.mcxpl;
  let mcxbrok = props.mcxbrok;
  let mcxvol = props.mcxvol;
  let optionspl = props.optionspl;
  let optionsbrok = props.optionsbrok;
  let optionsvol = props.optionsvol;

  let start = props.start;
  let end = props.end;

  let currentPageContent = []; // Track the content of the current page
  const pages = []; // Store all pages

  pages.push(
    <Page key={props.username} size="A4" style={styles.page}>
      <View style={styles.headingText2}>
        <Text
          style={[
            styles.Family,
            {
              fontSize: 12,
              marginBottom: "8px",
              textAlign: "center",
            },
          ]}
        >
          SOFT
        </Text>
      </View>
      {/* second sub-heading */}
      <View style={styles.subHeading}>
        <Text
          style={[
            styles.Family,
            {
              width: "42%",
              backgroundColor: "#C6C6C5",
              padding: "4px",
              textAlign: "left",
              fontSize: "11px",
            },
          ]}
        >
          {username} ({name})
        </Text>

        <Text
          style={[
            styles.Family,
            {
              width: "46%",
              backgroundColor: "#C6C6C5",
              padding: "4px",
              textAlign: "right",
              fontSize: "9px",
            },
          ]}
        >
          Account Statement From {moment(start).format("DD-MM-YYYY")} To{" "}
          {moment(end).format("DD-MM-YYYY")}
        </Text>
      </View>

      {currentPageContent}
      <View
        style={styles.footer}
        render={({ pageNumber, totalPages }) => (
          <Text style={{ fontSize: 8, marginBottom: "10px" }}>
            {" "}
            Page {pageNumber} of {totalPages}
          </Text>
        )}
        fixed
      />
      {/* Add the LastFooterSection component after the last data */}
      <LastFooterSection
        sharingp={sharingp}
        netamount={netamount}
        breture={breture}
        sharing={sharing}
        m2m={m2m}
        brokerage={brokerage}
        pl={pl}
        futurepl={futurepl}
        futurebrok={futurebrok}
        futurevol={futurevol}
        mcxpl={mcxpl}
        mcxbrok={mcxbrok}
        mcxvol={mcxvol}
        optionspl={optionspl}
        optionsbrok={optionsbrok}
        optionsvol={optionsvol}
      />
    </Page>
  );

  return (
    <PDFDownloadLink
      style={{
        textDecoration: "none",
      }}
      document={<Document>{pages}</Document>}
      fileName={`${props.username}(${props.name})`}
    >
      {({ blob, url, loading, error }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ color: Dark.text }}>DOWNLOAD PDF</div>
        </div>
      )}
    </PDFDownloadLink>
  );
};

export default PdfDocument;
