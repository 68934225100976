import React, { useContext, useEffect, useState } from "react";
import RightCard from "./RightCard/RightCard";
import ExtraCard from "./ExtraCard/ExtraCard";
import { Dark } from "../../../Theme/Theme";
import Marquee from "./Marquee";
import useKeypress from "react-use-keypress";

export default function Home() {


  const height = window.innerHeight;

  const [show, setShow] = useState(true);

  useKeypress("Escape", () => {
    setShow(!show);
  });

  return (
    <div
      style={{
        backgroundColor: Dark.secondary,
        height: height - 140,
      }}
    >
      <div
        style={{
          height: "45px",
          margin: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
        }}
      >
        <Marquee />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 4,
            margin: 10,
            borderRadius: 10,
            backgroundColor: Dark.background,
          }}
        >
          <RightCard
            status={show}
            show={() => setShow(true)}
            hide={() => setShow(false)}
          />
        </div>
        {show ? (
          <div
            style={{
              flex: 1.3,
              margin: 10,
              borderRadius: 10,
              backgroundColor: Dark.background,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExtraCard />
          </div>
        ) : null}
      </div>
    </div>
  );
}
