import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import LivePl from "../LivePl/LivePl";
import { NumericFormat } from "react-number-format";

export default function PosistionCard(props) {
  const item = props.item;
  const [total, setTotal] = useState(0);

  const [pfdata, setPfdata] = useState([]);

  const LivePlTotal = (a, b) => {
    if (item.standing.length > 0) {
      var newpfdata = pfdata;

      if (Array.isArray(newpfdata)) {
        var found = false;

        newpfdata.map((i1, t1) => {
          if (i1.id == a) {
            found = t1;
          }
        });

        if (found === false) {
          newpfdata.push({
            id: a,
            pf: b,
          });

          var totals = 0;
          newpfdata.map((i3) => {
            total = parseFloat(totals) + parseFloat(i3.pf);
          });
          setPfdata(newpfdata);
          setTotal(total);
        } else {
          newpfdata[found].pf = b;
          var total = 0;
          newpfdata.map((i) => {
            total = parseFloat(total) + parseFloat(i.pf);
          });

          setPfdata(newpfdata);
          setTotal(total);
        }
      } else {
        newpfdata.push({
          id: a,
          pf: b,
        });
        setPfdata(newpfdata);
        setTotal(b);
      }
    }
  };

  let profit_loss_week = parseFloat(item.profit_loss_week).toFixed(0);
  return (
    <div
      key={item.id}
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      {item.standing.length > 0
        ? item.standing.map((i) => {
            return (
              <LivePl
                item={i}
                key={i.id}
                LivePlTotal={LivePlTotal}
              />
            );
          })
        : null}
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div>{item.username}</div>
        <div style={{ fontSize: 12, paddingTop: 3 }}>({item.name})</div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
          fontSize: 16,
        }}
      >
        <div>
          <NumericFormat
            value={item.deposit_total.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div>
          {" "}
          <NumericFormat
            value={item.deposit_current.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
      <div
        style={{
          color: profit_loss_week > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div>
          {" "}
          <NumericFormat
            value={profit_loss_week}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
      <div
        style={{
          color:
            item.standing.length > 0
              ? total > 0
                ? Dark.buy
                : Dark.sell
              : Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.standing.length > 0 ? (
          <NumericFormat
            value={total.toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "NO STANDING"
        )}
        {item.standing.length > 0 ? "₹" : null}
      </div>

      <div
        style={{
          color:
            item.standing.length > 0
              ? parseFloat(parseFloat(total) + parseFloat(profit_loss_week)) > 0
                ? Dark.buy
                : Dark.sell
              : Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div>
          {" "}
          <NumericFormat
            value={parseFloat(parseFloat(total) + parseFloat(profit_loss_week)).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          ₹
        </div>
      </div>
    </div>
  );
}
