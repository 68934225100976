import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import LivePl from "../../../Healer/LivePl";
import { useDispatch } from "react-redux";
import Loading from "react-loading";
import Total from "./Total/Total";

const backend = new Backend();

export default function Position() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(false);
  const [standing, setStanding] = useState([]);
  const [standingsearch, setStandingSearch] = useState([]);
  const [booked, setBooked] = useState(0);
  const [super_id, setSuperId] = useState("");
  const [master_id, setMasterId] = useState("");
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSuperStanding = (a) => {
    setSuperId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      super_id: a,
      master_id: master_id,
      client_id: client_id,
      symbol: symbol,
    };
    backend.load_super_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMasterStanding = (a) => {
    setMasterId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      super_id: super_id,
      master_id: a,
      client_id: client_id,
      symbol: symbol,
    };
    backend.load_master_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientStanding = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      super_id: super_id,
      master_id: master_id,
      client_id: a,
      symbol: symbol,
    };
    
    backend.load_client_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolStanding = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      super_id: super_id,
      master_id: master_id,
      client_id: client_id,
      symbol: a,
    };
    backend.load_symbol_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Update = (a) => {
    dispatch({
      type: "CLEAR_PF",
    });

    setStanding(a);
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "SuperUp") {
      data = standingsearch.sort((a, b) =>
        a.super_username > b.super_username ? 1 : -1
      );
    } else if (s == "SuperDown") {
      data = standingsearch.sort((a, b) =>
        a.super_username < b.super_username ? 1 : -1
      );
    }else if (s == "MasterUp") {
      data = standingsearch.sort((a, b) =>
        a.master_username > b.master_username ? 1 : -1
      );
    } else if (s == "MasterDown") {
      data = standingsearch.sort((a, b) =>
        a.master_username < b.master_username ? 1 : -1
      );
    } else if (s == "ClientUp") {
      data = standingsearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = standingsearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "SymbolUp") {
      data = standingsearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = standingsearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuySellUp") {
      data = standingsearch.sort((a, b) => (a.buy_sell > b.buy_sell ? 1 : -1));
    } else if (s == "BuySellDown") {
      data = standingsearch.sort((a, b) => (a.buy_sell < b.buy_sell ? 1 : -1));
    } else if (s == "QtyUp") {
      data = standingsearch.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    } else if (s == "QtyDown") {
      data = standingsearch.sort((a, b) => (a.qty < b.qty ? 1 : -1));
    } else if (s == "RateUp") {
      data = standingsearch.sort((a, b) => (a.rate > b.rate ? 1 : -1));
    } else if (s == "RateDown") {
      data = standingsearch.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    }

    setStanding(data);
  };


  return (
    <div
      key={key}
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      {standing.length > 0
        ? standing.map((i) => {
            return <LivePl key={i.id} item={i} total={standing.length} />;
          })
        : null}
      <div>
        <TopCard
          booked={booked}
          super_id={super_id}
          master_id={master_id}
          client_id={client_id}
          symbol_id={symbol}
          reload={loadStanding}
          super={loadSuperStanding}
          master={loadMasterStanding}
          client={loadClientStanding}
          symbol={loadSymbolStanding}
        />
      </div>
      <div style={{ backgroundColor: Dark.background }}>
        <Total standing={standingsearch} Update={Update} />
        <Menu sort={sort} />
        <div
          className="scroll"
          style={{ height: "60vh", overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : standing.length > 0 ? (
            standing.map((i, t) => {
              return (
                <PositionCard
                  key={i.id}
                  item={i}
                  total={standing.length}
                  load={loadStanding}
                />
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
