import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },

  container: {
    width: "100%",
    marginTop: "15px",
  },
  Boxwrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 15px 0px 15px",
    borderBottom: "2px",
    borderColor: "black",
  },
  Box: {
    width: "31%",
    flexDirection: "column",
    marginBottom: "5px",
  },
  resultContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
  },

  LastSectionContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },

  LastSection: {
    width: "98%",
    marginTop: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  RightLastSection: {
    width: "40%",
    flexDirection: "column",
    gap: "9px",
  },
  rightContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "9px",
  },
});

// ------ JSX SECTION ---------
const Header = (props) => {
  let netamount = parseFloat(
    props.netamount == null ? 0 : props.netamount
  ).toFixed(0);


  netamount = netamount > 0 || netamount == 0 ? netamount : netamount.replace("-","")

  let breture = parseFloat(
    props.breture == null ? 0 : props.breture
  ).toFixed(0);


  let sharing = parseFloat(
    props.sharing == null ? 0 : props.sharing
  ).toFixed(0);

  sharing = sharing > 0 || sharing == 0 ? sharing : sharing.replace("-","")

  let pl = parseFloat(props.pl == null ? 0 : props.pl).toFixed(
    0
  );

  pl = pl > 0 || pl == 0 ? pl : pl.replace("-","")

  let brokerage = parseFloat(
    props.brokerage == null ? 0 : props.brokerage
  ).toFixed(0);

  let m2m = parseFloat(
    props.m2m == null ? 0 : props.m2m
  ).toFixed(0);

  m2m = m2m > 0 || m2m == 0 ? m2m : m2m.replace("-","")

  let amount = parseFloat(props.amount == null ? 0 : props.amount).toFixed(0);

  let futurepl = parseFloat(
    props.futurepl == null ? 0 : props.futurepl
  ).toFixed(0);


  futurepl = futurepl > 0 || futurepl == 0 ? futurepl : futurepl.replace("-","")


  let futurebrok = parseFloat(
    props.futurebrok == null ? 0 : props.futurebrok
  ).toFixed(0);
  let futurevol = parseFloat(
    props.futurevol == null ? 0 : props.futurevol
  ).toFixed(0);

  let mcxpl = parseFloat(
    props.mcxpl == null ? 0 : props.mcxpl
  ).toFixed(0);


  mcxpl = mcxpl > 0 || mcxpl == 0 ? mcxpl : mcxpl.replace("-","")

  let mcxbrok = parseFloat(props.mcxbrok == null ? 0 : props.mcxbrok).toFixed(
    0
  );
  let mcxvol = parseFloat(props.mcxvol == null ? 0 : props.mcxvol).toFixed(0);

  let optionspl = parseFloat(
    props.optionspl == null ? 0 : props.optionspl
  ).toFixed(0);

  optionspl = optionspl > 0 || optionspl == 0 ? optionspl : optionspl.replace("-","")

  let optionsbrok = parseFloat(
    props.optionsbrok == null ? 0 : props.optionsbrok
  ).toFixed(0);
  let optionsvol = parseFloat(
    props.optionsvol == null ? 0 : props.optionsvol
  ).toFixed(0);

  let m2mfuture = parseFloat(
    parseFloat(futurepl) + parseFloat(futurebrok)
  ).toFixed(0);

  m2mfuture = m2mfuture > 0 || m2mfuture == 0 ? m2mfuture : m2mfuture.replace("-","")

  let m2mmcx = parseFloat(parseFloat(mcxpl) + parseFloat(mcxbrok)).toFixed(0);

  m2mmcx = m2mmcx > 0 || m2mmcx == 0 ? m2mmcx : m2mmcx.replace("-","")

  let m2mfo = parseFloat(
    parseFloat(optionspl) + parseFloat(optionsbrok)
  ).toFixed(0);

  m2mfo = m2mfo > 0 || m2mfo == 0 ? m2mfo : m2mfo.replace("-","")

 

  return (
    <View style={styles.container}>
      {/* box wrapper */}
      <View style={styles.Boxwrapper}>
        {/* MCX SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            MCX
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Gross</Text>
              <Text>
                {m2mmcx}
                {m2mmcx > 0 ? " Cr" : " Dr"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Brok</Text>
              <Text>
                {parseFloat(mcxbrok).toFixed(0)} {"Dr"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Net Amt</Text>
              <Text>
                {mcxpl} {mcxpl > 0 ? "Cr" : "Dr"}
              </Text>
            </View>
          </View>
        </View>

        {/* NSE SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            NSE
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Gross</Text>
              <Text>
                {m2mfuture}
                {m2mfuture > 0 ? " Cr" : " Dr"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Brok</Text>
              <Text>{futurebrok} Dr</Text>
            </View>
            <View style={styles.content}>
              <Text>Net Amt</Text>
              <Text>
                {futurepl} {futurepl > 0 ? "Cr" : "Dr"}
              </Text>
            </View>
          </View>
        </View>

        {/* NCDEX SECTION */}
        <View style={styles.Box}>
          <Text
            style={{
              padding: "3px 0px 3px 0px",
              backgroundColor: "#C6C6C5",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            NCDEX
          </Text>
          {/* gross, brok, net Amt */}

          <View style={styles.resultContent}>
            <View style={styles.content}>
              <Text>Gross</Text>
              <Text>
                {m2mfo}
                {m2mfo > 0 ? " Cr" : " Dr"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text>Brok</Text>
              <Text>{optionsbrok} Dr</Text>
            </View>
            <View style={styles.content}>
              <Text>Net Amt</Text>
              <Text>
                {optionspl} {optionspl > 0 ? "Cr" : "Dr"}
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* LAST SECTION */}
      <View style={styles.LastSectionContainer}>
        <View style={styles.LastSection}>
          {/* left side */}
          <Text style={{ width: "10%", fontSize: "8px", marginTop: "40px" }}>
            E.&.O.E
          </Text>

          <View style={styles.RightLastSection}>
            <View style={styles.rightContent}>
              <Text>Gross Amount</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{m2m}</Text>
                <Text>{m2m > 0 ? "Cr" : " Dr"}</Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text>Brokerage</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{brokerage}</Text>
                <Text style={{ color: "black" }}>{"Dr"}</Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>Profit/Loss</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{pl}</Text>
                <Text style={{ fontFamily: "Times-Bold" }}>
                  {pl > 0 ? " Cr" : " Dr"}
                </Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>
                Sharing ({props.sharingp})
              </Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{sharing}</Text>
                <Text style={{ fontFamily: "Times-Bold" }}>
                  {sharing > 0 ? " Cr" : " Dr"}
                </Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>Brokerage Reture</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{breture}</Text>
                <Text style={{ fontFamily: "Times-Bold" }}>{"Dr"}</Text>
              </View>
            </View>

            <View style={styles.rightContent}>
              <Text style={{ fontFamily: "Times-Bold" }}>Net Bill Amt</Text>
              <View style={{ flexDirection: "row", gap: "15px" }}>
                <Text>{netamount}</Text>
                <Text style={{ fontFamily: "Times-Bold" }}>
                  {netamount > 0 ? " Cr" : " Dr"}
                </Text>
              </View>
            </View>

            <View
              style={{ flexDirection: "row", gap: "17px", marginTop: "5px" }}
            >
              <Text style={{ fontSize: "7px" }}>For</Text>
              <Text style={{ fontSize: "12", fontFamily: "Times-Bold" }}>
                SOFT
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* container ending tag */}
    </View>
  );
};

export default Header;
