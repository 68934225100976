import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import "./News.css";
import { InputText } from "primereact/inputtext";
import Loading from "react-loading";
import { Dropdown } from "primereact/dropdown";
const backend = new Backend();

const list = [
  { name: "Good News", value: "0" },
  { name: "Success", value: "1" },
  { name: "Warning", value: "2" },
  { name: "Danger", value: "3" },
];

export default function News() {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.news(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setNews(r.news);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const addNews = () => {
    if (msg == "" || type == "") {
      toast.error("Invalid News Msg", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        title: list[type].name,
        news: msg,
        type: list[type].value,
      };

      backend.add_news(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          setMsg("");
          setType("");
          loadNews();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  const deleteNews = (a) => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      news_id: a,
    };

    backend.delete_news(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        loadNews();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 55,
          display: "flex",
          backgroundColor: Dark.background,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          borderRadius: 10,
          paddingLeft: 10,
        }}
      >
        <div
          style={{
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            marginLeft: 10,
          }}
        >
          ADD NEW NEWS
        </div>

        <div
          key={loadNews}
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          <div className="loginInputAddAdmin">
            <InputText
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              style={{
                height: 35,
                marginRight: 20,
              }}
              placeholder="Enter New News"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={type}
              onChange={(e) => {
                setType(e.value);
              }}
              options={list}
              optionLabel="name"
              placeholder="Select a Type"
            />
          </div>
        </div>

        <div
          onClick={addNews}
          style={{
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            marginRight: 10,
            backgroundColor: Dark.primary,
            padding: 10,
            borderRadius: 10,
          }}
        >
          SUBMIT
        </div>
      </div>

      <div
        className="scrolldivu"
        style={{ height: "46vh", overflowY: "scroll" }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Loading type="spin" height={30} width={30} color={Dark.text} />
          </div>
        ) : news.length > 0 ? (
          news.map((i, t) => {
            return (
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  backgroundColor: Dark.background,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  borderRadius: 10,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  {t + 1}
                </div>
                <div
                  style={{
                    color: Dark.text,
                    flex: 1,
                    fontSize: 14,
                    paddingLeft: 15,
                  }}
                >
                  {i.msg}
                </div>
                <div
                  style={{
                    color:
                      i.type == 0
                        ? Dark.primary
                        : i.type == 1
                        ? Dark.buy
                        : i.type == 2
                        ? Light.primary
                        : Dark.sell,
                    flex: 0.2,
                    fontSize: 14,
                    paddingLeft: 15,
                  }}
                >
                  {i.title}
                </div>
                <div
                  onClick={() => {
                    deleteNews(i.id);
                  }}
                  style={{
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 12,
                    marginRight: 10,
                    backgroundColor: Dark.sell,
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  DELETE
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textTransform:"uppercase"
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No News Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
