import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import Filter from "./Filter/Filter";
import moment from "moment";
import { NumericFormat } from "react-number-format";

export default function TopCard(props) {
  let brokerage = parseFloat(
    props.brokerage == null ? 0 : props.brokerage
  ).toFixed(0);
  let pl = parseFloat(props.pl == null ? 0 : props.pl).toFixed(0);
  let m2m = parseFloat(parseFloat(pl) + parseFloat(brokerage)).toFixed(0);

  let futurepl = parseFloat(
    props.futurepl == null ? 0 : props.futurepl
  ).toFixed(0);
  let futurebrok = parseFloat(
    props.futurebrok == null ? 0 : props.futurebrok
  ).toFixed(0);
  let futurevol = parseFloat(
    props.futurevol == null ? 0 : props.futurevol
  ).toFixed(0);

  let mcxpl = parseFloat(props.mcxpl == null ? 0 : props.mcxpl).toFixed(0);
  let mcxbrok = parseFloat(props.mcxbrok == null ? 0 : props.mcxbrok).toFixed(
    0
  );
  let mcxvol = parseFloat(props.mcxvol == null ? 0 : props.mcxvol).toFixed(0);

  let optionspl = parseFloat(
    props.optionspl == null ? 0 : props.optionspl
  ).toFixed(0);
  let optionsbrok = parseFloat(
    props.optionsbrok == null ? 0 : props.optionsbrok
  ).toFixed(0);
  let optionsvol = parseFloat(
    props.optionsvol == null ? 0 : props.optionsvol
  ).toFixed(0);

  const [filter, setFilter] = useState(false);
  const [filtertype, setFilterType] = useState("");

  const clearfilter = () => {
    setFilterType("")
    props.filter(new Date(), new Date());
    props.reload()
  };

  return (
    <div
      style={{
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 2,
          borderRightColor: Dark.primary,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 40,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div>BROKERAGE</div>
            <div
              style={{
                marginTop: 5,
                color: Dark.sell,
              }}
            >
              <NumericFormat
                value={brokerage}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              ₹
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div>M2M</div>
            <div
              style={{
                marginTop: 5,
                color: pl + brokerage > 0 ? Dark.buy : Dark.sell,
              }}
            >
              <NumericFormat
                value={m2m}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              ₹
            </div>
          </div>
        </div>
        <div
          style={{
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div>NET AMOUNT</div>
          <div
            style={{
              marginTop: 5,
              color: pl > 0 ? Dark.buy : Dark.sell,
            }}
          >
            <NumericFormat
              value={pl}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 2,
          borderRightColor: Dark.primary,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div style={{ marginBottom: 7 }}>FUTURE</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={futurebrok}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: futurepl + futurebrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={parseFloat(
                    parseFloat(futurepl) + parseFloat(futurebrok)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={futurevol}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: futurepl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={futurepl}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div style={{ marginBottom: 7 }}>MCX</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={mcxbrok}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: mcxpl + mcxbrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={parseFloat(parseFloat(mcxpl) + parseFloat(mcxbrok))}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={mcxvol}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: mcxpl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={mcxpl}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 7 }}>OPTIONS</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={optionsbrok}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: optionspl + optionsbrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={parseFloat(
                    parseFloat(optionspl) + parseFloat(optionsbrok)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={optionsvol}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: optionspl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={optionspl}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                ₹
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div
            onClick={clearfilter}
            style={{
              color: filtertype == "" ? Dark.text : Dark.sell,
              fontSize: 14,
            }}
          >
            {filtertype == "" ? "FILTER" : "CLEAR"}
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>
      </div>
      <Dialog
        header={"Filter List"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>
    </div>
  );
}
