import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import Filter from "./Filter/Filter";
import moment from "moment";

export default function TopCard(props) {
  let brokerage = props.brokerage == null ? 0 : props.brokerage;
  let pl = props.pl == null ? 0 : props.pl;

  let futurepl = props.futurepl == null ? 0 : props.futurepl;
  let futurebrok = props.futurebrok == null ? 0 : props.futurebrok;
  let futurevol = props.futurevol == null ? 0 : props.futurevol;

  let mcxpl = props.mcxpl == null ? 0 : props.mcxpl;
  let mcxbrok = props.mcxbrok == null ? 0 : props.mcxbrok;
  let mcxvol = props.mcxvol == null ? 0 : props.mcxvol;

  let optionspl = props.optionspl == null ? 0 : props.optionspl;
  let optionsbrok = props.optionsbrok == null ? 0 : props.optionsbrok;
  let optionsvol = props.optionsvol == null ? 0 : props.optionsvol;

  const [filter, setFilter] = useState(false);
  const [filtertype, setFilterType] = useState("");


  const clearfilter = () => {
    setFilterType("")
    props.filter(new Date(), new Date());
    props.reload()
  };

  return (
    <div
      style={{
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform:"uppercase"
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 2,
          borderRightColor:Dark.primary
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 40,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div>BROKERAGE</div>
            <div
              style={{
                marginTop: 5,
                color: Dark.sell,
              }}
            >
              {parseFloat(brokerage).toFixed(2)}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div>M2M</div>
            <div
              style={{
                marginTop: 5,
                color: pl + brokerage > 0 ? Dark.buy : Dark.sell,
              }}
            >
              {parseFloat(pl + brokerage).toFixed(2)}
            </div>
          </div>
        </div>
        <div
          style={{
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div>NET AMOUNT</div>
          <div
            style={{
              marginTop: 5,
              color: pl > 0 ? Dark.buy : Dark.sell,
            }}
          >
            {parseFloat(pl).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 2,
          borderRightColor:Dark.primary
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor:Dark.primary
          }}
        >
          <div style={{ marginBottom: 7 }}>FUTURE</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(futurebrok).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: futurepl + futurebrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(futurepl + futurebrok).toFixed(2)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(futurevol).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: futurepl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(futurepl).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor:Dark.primary
          }}
        >
          <div style={{ marginBottom: 7 }}>MCX</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(mcxbrok).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: mcxpl + mcxbrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(mcxpl + mcxbrok).toFixed(2)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(mcxvol).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: mcxpl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(mcxpl).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 7 }}>OPTIONS</div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>BROKERAGE</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(optionsbrok).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>M2M</div>
              <div
                style={{
                  color: optionspl + optionsbrok > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(optionspl + optionsbrok).toFixed(2)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 7, width: "100%" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>VOLUME</div>
              <div
                style={{
                  color: Dark.sell,
                }}
              >
                {parseFloat(optionsvol).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12 }}>NET AMOUNT</div>
              <div
                style={{
                  color: optionspl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                {parseFloat(optionspl).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
         <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div
            onClick={clearfilter}
            style={{
              color: filtertype == "" ? Dark.text : Dark.sell,
              fontSize: 14,
            }}
          >
            {filtertype == "" ? "FILTER" : "CLEAR"}
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>
      </div>
      <Dialog
        header={"Filter List"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>
    </div>
  );
}
