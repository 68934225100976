import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
export default function TradeCard(props) {
  const i = props.item;
  let m2m = parseFloat(
    parseFloat(i.profit_loss) + parseFloat(i.brokerage_client_amt)
  ).toFixed(0);
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textTransform: "uppercase",
        }}
      >
        {i.super_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textTransform: "uppercase",
        }}
      >
        {i.master_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textTransform: "uppercase",
        }}
      >
        {i.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          flexDirection: "column",
        }}
      >
        <div>{i.symbol}</div>
        <div style={{ fontSize: 10, paddingTop: 5 }}>
          {moment(i.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: i.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.rate}
      </div>
      <div
        style={{
          color:
            i.type == "NEW SAUDA"
              ? Dark.primary
              : i.type == "SAUDA ADDED"
              ? Dark.buy
              : i.type == "SAUDA REMOVE"
              ? Dark.sell
              : i.type == "SAUDA SQ.Off"
              ? Dark.sell
              : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.type}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.new_deposit}
      </div>
      <div
        style={{
          color: m2m > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.type == "NEW SAUDA" || i.type == "SAUDA ADDED"
          ? "0"
          : parseFloat(m2m).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.brokerage_client_amt}
      </div>
      <div
        style={{
          color: i.profit_loss > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {parseFloat(i.profit_loss).toFixed(0)}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 13,
          textAlign:"center"
        }}
      >
        {i.other == null ? "-" : i.other}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 10,
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>{moment(i.date_created).format("DD-MM-YYYY")}</div>
        <div>{moment(i.date_created).format("HH:mm:ss")}</div>
      </div>
    </div>
  );
}
