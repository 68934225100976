import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";

const backend = new Backend();

export default function PositionSuper() {
  const [loading, setLoading] = useState(false);
  const [supers, setSuper] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_standing_super(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSuper(r.super);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      <div style={{ backgroundColor: Dark.background }}>
        <Menu />
        <div
          className="scroll"
          // onScroll={handleScroll}
          style={{ height: "78vh", overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : supers.length > 0 ? (
            supers.map((i, t) => {
              return (
                <PositionCard
                  item={i}
                  load={loadStanding}
                />
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
